import React, { useEffect, useState } from 'react';
import Cookie from 'js-cookie';
import { useLayoutContext } from '@ott/layout-context';

import { lang } from '@ott/l10n';

import { MetaWebBanner as MetaWebBannerComponent } from '@ott/meta-web-banner';

import { checkIsMetaWebBanner } from 'utility/abTest';
import { isOttBrand } from 'src/helpers/ottBrand';

const showMetaWebBanner = checkIsMetaWebBanner();

const MetaWebBanner = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const { isMobile } = useLayoutContext();

  if (
    typeof window === 'undefined' ||
    lang !== 'ru' ||
    // @ts-ignore
    __WHITE_LABEL__ ||
    isOttBrand() ||
    !showMetaWebBanner ||
    !isMobile
  ) {
    return null;
  }

  useEffect(() => {
    const modalShown = sessionStorage.getItem('metaWebBannerShown');

    if (!modalShown) {
      setIsModalVisible(true);
      sessionStorage.setItem('metaWebBannerShown', 'true');

      // для того чтоб не было прыжка в анимации появления баннера
      setTimeout(() => {
        setIsMounted(true);
      }, 500);
    }
  }, []);

  useEffect(() => {
    // баннер отображаем поверх всей главной
    // поэтому чтобы задизейблить скролл вешаем overflow: hidden на body
    const bodyElement = document.getElementsByTagName('body')[0] as HTMLBodyElement;

    if (isModalVisible) {
      bodyElement.style.overflow = 'hidden';
    } else {
      bodyElement.style.overflow = 'auto';
    }
  }, [isModalVisible]);

  const metricParams = {
    lang,
    page: 'index_avia',
    product: 'avia',
    referrer_mrk: Cookie.get('referrer_mrk'),
    isAdaptive: isMobile,
  };

  return (
    <MetaWebBannerComponent
      isOpen={isModalVisible && isMounted}
      onIsOpenChange={setIsModalVisible}
      metricParams={metricParams}
    />
  );
};

export default MetaWebBanner;
