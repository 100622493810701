import { getAvailableTests } from '@ott/ab-test';

const availableTests: string[] = getAvailableTests();


// https://onetwotripdev.atlassian.net/browse/FI-1021
// Установка мобильного приложения
const META_WEB_BANNER = 'm38_b';

export const checkIsMetaWebBanner = () =>
  availableTests.includes(META_WEB_BANNER);

/* -------------------------------------------------------------------------------------------------
 * Ссылка "Командировки
 * -----------------------------------------------------------------------------------------------*/
const B2B_TRIPS_BUTTON_INLINED = 'm28_a';
const B2B_TRIPS_BUTTON_SEPARATED = 'm28_b';

/**
 * Определяет положение ссылки "Командировки" на главной странице.
 * Если позиция не определена, не отображаем ссылку.
 * {@link https://onetwotripdev.atlassian.net/browse/REF-108|Задача}
 */
export const getB2bButtonPosition = () => {
  const availableTestsClosure = getAvailableTests();

  switch (true) {
    case availableTestsClosure.includes(B2B_TRIPS_BUTTON_SEPARATED):
      return 'separated';
    case availableTestsClosure.includes(B2B_TRIPS_BUTTON_INLINED):
    default:
      return 'inlined';
  }
};
